import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';

type Props = {};

const Comunity = (props: Props) => {
    return (
        <div className='w-full mt-8'>
            <div className='container '>
                <div className='w-[90%] mx-auto my-0'>
                    <div className='w-full text-center'>
                        <h1 className='text-4xl md:text-6xl lg:text-7xl font-bold mb-10'>Community</h1>
                    </div>
                    <div className='w-full mx-auto flex flex-col md:flex-row gap-4 justify-center items-center'>
                        <div className='w-full md:w-1/2'>
                            <img src="assets/spike.gif" className='w-full h-auto md:h-[550px] rounded-3xl' alt="" />
                        </div>
                        <div className='w-full md:w-1/2 flex flex-col justify-center items-center gap-3'>
                            <img src="assets/SPIKE-JWU.png" className='w-1/2 md:w-1/3' alt="" />
                            <a href='https://t.me/SpikeDoge' target="_blank" rel="noreferrer" className='flex gap-3 text-white bg-[#2723FF] justify-center items-center py-3 px-5 border-2 border-black rounded-full mt-4 md:mt-0'>
                                <FaTelegramPlane />
                                <p>TELEGRAM</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comunity;