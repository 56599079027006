import React from 'react';

type Props = {};

const Tokenimics = (props: Props) => {
  return (
    <div className='w-full mt-8'>
      <div className='container mx-auto'>
        <div className='w-full text-center'>
          <h1 className='text-4xl md:text-6xl lg:text-7xl font-bold mb-6'>Tokenimics</h1>
        </div>
        <div className='w-full mx-auto flex flex-wrap gap-4 justify-center'>
          <div className='w-full md:w-1/3 lg:w-1/4'>
            <div className='w-full h-[300px] md:h-[400px] flex flex-col items-center gap-6'>
              <img src="assets/SPIKE-coin1.png" className='w-[40%] md:w-[55%] h-full object-contain' alt="" />
              <h1 className='text-xl md:text-3xl font-semibold'>TOTAL SUPPLY</h1>
            </div>
          </div>
          <div className='w-full md:w-1/3 lg:w-1/4'>
            <div className='w-full h-[300px] md:h-[400px] flex flex-col items-center gap-6'>
              <img src="assets/SPIKE-coin2.png" className='w-[40%] md:w-[55%] h-full object-contain' alt="" />
              <h1 className='text-xl md:text-3xl font-semibold'>LP BURNT</h1>
            </div>
          </div>
          <div className='w-full md:w-1/3 lg:w-1/4'>
            <div className='w-full h-[300px] md:h-[400px] flex flex-col items-center gap-6'>
              <img src="assets/SPIKE-coin.png" className='w-[40%] md:w-[55%] h-full object-contain' alt="" />
              <h1 className='text-xl md:text-3xl font-semibold'>TAX</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenimics;