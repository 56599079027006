import React from 'react'

type Props = {}

const Banner = (props: Props) => {
    return (
        <div className='w-full'>
            <div className="relative h-[400px] lg:h-[650px] flex overflow-hidden">
                <div className='absolute w-full h-full'>
                    <img src="assets/SPIKE-banner.png" className='w-full h-full' alt="" />
                </div>
                <div className='w-2/3 h-full'></div>
                <div className='w-1/3 h-full'>
                    <div className='relative w-full h-full'>
                    </div>
                </div>
            </div>
            <div className='w-full text-center mt-5 '>
                <p className='text-3xl break-words'>CA: 9t3HFgfv5NLbGEj9ds5GQMynGg8d6g2vXcfdTLZzABwd</p>
            </div>
        </div>
    )
}

export default Banner