import React from 'react';

const Header = () => {
  return (
    <div className='w-full'>
      <div className='container mx-auto py-7'>
        <h2 className='text-center pb-3 text-4xl md:text-6xl lg:text-8xl font-bold'>
          Spike Doge
        </h2>
        <p className='text-center text-2xl md:text-4xl lg:text-5xl font-medium'>
          Own onli $SPIKE nd be happy
        </p>
      </div>
    </div>
  );
};

export default Header;