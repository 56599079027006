import React from 'react';
import './App.css';
import './styles/index.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from './components/Header';
import Banner from './components/Banner';
import About from './components/About';
import HowToBuy from './components/HowToBuy';
import Tokenimics from './components/Tokenimics';
import Comunity from './components/Comunity';
import Memes from './components/Meme';
import Footer from './components/Footer';
import Navbar from './components/Navbar';

function App() {
  return (
    <React.Fragment>
      <Header />
      <Navbar />
      <Banner />
      <About />
      <HowToBuy />
      <Tokenimics />
      <Comunity />
      <Memes />
      <Footer />
    </React.Fragment>
  );
}

export default App;
