import React from 'react'
import Slider from "react-slick";
import lodash from "lodash";

type Props = {}

const Footer = (props: Props) => {
    const settings = {
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        speed: 9000,
        autoplaySpeed: 0,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };
    return (
        <div className='w-full bg-[#2723FF] mt-5'>
            <div className='container py-10'>
                <div className='w-[90%] mx-auto py-0 relative'>
                    <div className='flex pb-5'>
                        <div className='w-1/2 text-white'>
                            <h1 className='font-bold text-3xl'>$SPIKE</h1>
                            <p className='font-semibold'>opyright ©2024 $SPIKE</p>
                            <p className='font-semibold'>Legal Disclaimer: $SPIKE is a meme coin with no intrinsic value
                                or expectation of financial return. $SPIKE is completely useless
                                and for entertainment purposes only. When you purchase $SPIKE, you
                                are agreeing that you have seen this disclaimer
                            </p>
                        </div>
                        <div className='w-1/2'>

                        </div>
                    </div>
                    <div className='w-full'>
                        <Slider {...settings} className='max-h-[420px]'>
                            {lodash.range(1, 12).map((val: number) => (
                                <div className=" px-4 py-3 " key={val}>
                                    <h1 className='text-white text-5xl font-black '>$SPIKE</h1>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer