import React, { useEffect, useState } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

type Props = {
    aboutRef?: React.RefObject<HTMLDivElement>;
    FAQRef?: React.RefObject<HTMLDivElement>;
    howToBuyRef?: React.RefObject<HTMLDivElement>;
    roadmapRef?: React.RefObject<HTMLDivElement>;
    toKenNomicRef?: React.RefObject<HTMLDivElement>;
    handClickScrollDown?: (e: React.RefObject<HTMLDivElement>) => void;
};

const Navbar: React.FC<Props> = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
                setVisible(true);
            } else {
                setVisible(false);
            }

            setPrevScrollPos(currentScrollPos);
        }

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    return (
        <div className={`w-full bg-white flex justify-start items-center px-4 py-4 wrap-header fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className='w-full flex items-center gap-4 overflow-x-auto'>
                <img src="assets/SPIKE-logo.png" className='w-12 h-12' alt="" />
                <a href='https://t.me/SpikeDoge' target="_blank" rel="noreferrer" className='flex items-center gap-3'>
                    <FaTelegramPlane />
                    <p>TELEGRAM</p>
                </a>
                <a href='https://x.com/account/access' target="_blank" rel="noreferrer" className='flex items-center gap-3'>
                    <FaXTwitter />
                    <p>Twitter</p>
                </a>
                <a href='https://spikedoge.info/' target="_blank" rel="noreferrer" className='flex items-center gap-3'>
                    <img src='assets/Dex.png' alt='' className='w-[20px] h-[20px]' />
                    <p>Dex</p>
                </a>
            </div>
        </div>
    );
};

export default Navbar;